import Button from "material-ui/Button";
import Icon from "material-ui/Icon";
import Table, { TableHead, TableBody } from "material-ui/Table";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import Layout from "../../../../components/Layout";
import Loader from "../../../../components/Loader";
import Pagination from "../../../../components/Pagination";
import SmartFab from "../../../../components/SmartFab";
import {
  loadBlogsData,
  setPage,
  removeBlog,
} from "../../../../modules/marketer/blog/index";
import ListHeaderRow from "../components/ListHeaderRow";
import ListRow from "../components/ListRow";

import ListSidebar from "./ListSidebar";

const {
  getBlogList,
  getBlogsTotal,
  getBlogsPage,
  getBlogsPageSize,
  getBlogsLoading,
  getBlogsLoaded,
} = require("../../../../selectors").default;

const styles = {
  top: {
    display: "flex",
    position: "relative",
    justifyContent: "space-between",
  },
  paginationContainer: {
    flex: "0 1 auto",
  },
  buttonsContainer: {
    flex: "0 1 auto",
  },
  addButton: {
    marginBottom: 5,
    marginLeft: 10,
  },
  notFound: {
    width: "100%",
    position: "absolute",
    top: "50%",
    textAlign: "center",
    marginTop: -10,
  },
  refresherContainer: {
    flex: "none",
    margin: "0 20px",
  },
};

const moduleUrl = "/marketer/blog";

const BlogListPage = (props) => {
  const {
    items,
    loadData,
    loaded,
    loading,
    handleCreate,
    handlePageChange,
    total,
    page,
    pageSize,
    handleOpen,
    handleRemove,
  } = props;

  useEffect(() => {
    loadData();
  }, []);

  const renderPagination = () => (
    <div style={styles.paginationContainer}>
      <Pagination
        total={total}
        current={page}
        pageSize={pageSize}
        onChange={handlePageChange}
      />
    </div>
  );

  const renderRefresher = () => (
    <div style={styles.refresherContainer}>
      <SmartFab processing={loading} onClick={loadData} icon="refresh" />
    </div>
  );

  const renderButton = () => (
    <div style={styles.buttonsContainer}>
      <Button
        raised
        color="primary"
        onClick={handleCreate}
        style={styles.addButton}
      >
        Новый <Icon className="material-icons">note_add</Icon>
      </Button>
    </div>
  );

  const renderTable = () => (
    <Table>
      <TableHead>
        <ListHeaderRow />
      </TableHead>
      <TableBody>
        {items.map((item) => (
          <ListRow
            key={item?.id}
            item={item}
            onOpen={handleOpen}
            onRemove={handleRemove}
          />
        ))}
      </TableBody>
    </Table>
  );

  const renderContent = () => {
    if (items.length === 0) {
      return <div style={styles.notFound}>Записи не найдены</div>;
    }

    return (
      <div>
        <div style={styles.top}>
          {renderPagination()}
          {renderRefresher()}
          {renderButton()}
        </div>
        {renderTable()}
      </div>
    );
  };

  if (!loaded && !loading) {
    return null;
  }

  return (
    <Layout
      content={loading && items.length === 0 ? <Loader /> : renderContent()}
      sidebar={loaded && <ListSidebar />}
    />
  );
};

export default withRouter(
  connect(
    (state) => ({
      items: getBlogList(state),
      total: getBlogsTotal(state),
      page: getBlogsPage(state),
      pageSize: getBlogsPageSize(state),
      loading: getBlogsLoading(state),
      loaded: getBlogsLoaded(state),
    }),
    (dispatch, ownProps) => ({
      loadData() {
        dispatch(loadBlogsData());
      },
      handlePageChange(number) {
        dispatch(setPage(number));
        dispatch(loadBlogsData());
      },
      handleOpen(item) {
        ownProps.history.push(`${moduleUrl}/${item.id}`);
      },
      handleRemove(id) {
        if (window.confirm(`Вы уверены, что хотите УДАЛИТЬ item ИД ${id}?`)) {
          dispatch(removeBlog(id));
        }
      },
      handleCreate() {
        console.log("create", ownProps.history);
        ownProps.history.push(`${moduleUrl}/new`);
      },
    })
  )(BlogListPage)
);
