import { TableRow } from "material-ui/Table";
import React from "react";

import { default as TableCell } from "../../../orders/components/OrdersTableCell";

const ListHeaderRow = () => (
  <TableRow>
    <TableCell />
    <TableCell>ИД</TableCell>
    <TableCell>Изображение</TableCell>
    <TableCell>Название</TableCell>
    <TableCell>h1</TableCell>
    <TableCell>Активность</TableCell>
    <TableCell>text</TableCell>
    <TableCell>создано</TableCell>
    <TableCell>Действие</TableCell>
  </TableRow>
);

export default ListHeaderRow;
