import axios from "axios";
import qs from "qs";
import { put, all, takeLatest, select } from "redux-saga/effects";

const {
  getBlogsPage,
  getBlogsPageSize,
  getBlog,
  getBlogsActivityTypesSelected,
  getBlogsTypesSelected,
} = require("../../../selectors").default;

const types = require("./types");

const moduleUrl = "/api/hits/news";
const dataField = "items";

function* loadBlogsDataSaga() {
  const state = yield select();

  const page = getBlogsPage(state);
  const pageSize = getBlogsPageSize(state);
  const offset = pageSize * (page - 1);

  const activityTypes = getBlogsActivityTypesSelected(state);
  const pickBy = (obj, predicate) =>
    Object.fromEntries(
      Object.entries(obj).filter(([key, val]) => predicate(val, key))
    );

  const result = yield axios({
    method: "GET",
    url: `${moduleUrl}/list`,
    params: pickBy(
      {
        offset,
        is_active: activityTypes.length === 1 ? activityTypes[0] : [],
        type_id: getBlogsTypesSelected(state),
      },
      (val) => !!val
    ),
    paramsSerializer(params) {
      return qs.stringify(params, { arrayFormat: "brackets" });
    },
    withCredentials: true,
  });

  yield put({
    type: types.LOAD_BLOGS_DATA_COMPLETED,
    blogs: result.data?.[dataField],
    pageSize: result.data.pageSize,
    total: result.data.total,
  });
}

function* removeBlogSaga({ id }) {
  yield axios({
    method: "POST",
    url: `${moduleUrl}/delete`,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: qs.stringify({
      id,
    }),
  });

  yield put({ type: types.LOAD_BLOGS_DATA });
}

function* loadOneBlogSaga({ id }) {
  let blog;
  if (Number.isNaN(id)) {
    const result = yield axios({
      method: "GET",
      url: `${moduleUrl}/create`,
      params: {
        id,
      },
      withCredentials: true,
    });
    blog = result.data;
  } else {
    const result = yield axios({
      method: "GET",
      url: `${moduleUrl}/view`,
      params: {
        id,
      },
      withCredentials: true,
    });
    blog = result.data;
  }

  yield put({
    type: types.LOAD_ONE_BLOG_COMPLETED,
    blog,
  });
}

function* loadBlogTypesSaga() {
  const result = yield axios({
    method: "GET",
    url: `${moduleUrl}/types`,
    withCredentials: true,
  });

  yield put({
    type: types.LOAD_BLOG_TYPES_COMPLETED,
    blogTypes: result.data,
  });
}

function* uploadBlogImageSaga({ data }) {
  console.log("uploadBlogImageSaga", data);
  const result = yield axios({
    method: "POST",
    url: `${moduleUrl}/upload`,
    data,
  });

  if (result.status !== 200) {
    console.error(result);
    return;
  }

  yield put({
    type: types.LOAD_ONE_BLOG_COMPLETED,
    blog: result.data,
  });
}

function* removeBlogImageSaga({ id, type }) {
  const result = yield axios({
    method: "POST",
    url: `${moduleUrl}/${
      type === "horizontal" ? "removeHorizontalImage" : "removeVerticalImage"
    }`,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: qs.stringify({
      id,
    }),
  });

  yield put({
    type: types.LOAD_ONE_BLOG_COMPLETED,
    blog: result.data,
  });
}

function* removeBlogHorizontalImageSaga({ id }) {
  yield removeBlogImageSaga({ id, type: "horizontal" });
}

function* removeBlogVerticalImageSaga({ id }) {
  yield removeBlogImageSaga({ id, type: "vertical" });
}

function* saveBlogSaga({ history }) {
  const state = yield select();
  const blog = getBlog(state);

  const result = yield axios({
    method: "POST",
    url: `${moduleUrl}/save`,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: qs.stringify({
      ...blog,
    }),
  });

  if (blog.id) {
    yield put({
      type: types.LOAD_ONE_BLOG_COMPLETED,
      blog: result.data,
    });
  } else {
    yield put({
      type: types.LOAD_ONE_BLOG,
      id: result.data.id,
    });
    history.push(`/marketer/blog/${result.data.id}`);
  }
}

export default function* () {
  yield all([
    takeLatest(types.LOAD_BLOGS_DATA, loadBlogsDataSaga),
    takeLatest(types.REMOVE_BLOG, removeBlogSaga),
    takeLatest(types.LOAD_ONE_BLOG, loadOneBlogSaga),
    // takeLatest(types.LOAD_BLOG_TYPES, loadBlogTypesSaga),
    takeLatest(types.UPLOAD_BLOG_IMAGE, uploadBlogImageSaga),
    takeLatest(
      types.REMOVE_BLOG_HORIZONTAL_IMAGE,
      removeBlogHorizontalImageSaga
    ),
    takeLatest(types.REMOVE_BLOG_VERTICAL_IMAGE, removeBlogVerticalImageSaga),
    takeLatest(types.SAVE_BLOG, saveBlogSaga),
  ]);
}
