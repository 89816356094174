// import R from "ramda";

// export const getBlogList = R.path(["blogs"]);

export const getBlogList = (state) => state.blogs;

export const getBlogsTotal = (state) => parseInt(state.total, 10) || 0;
export const getBlogsPage = (state) => parseInt(state.page, 10) || 0;
export const getBlogsPageSize = (state) => parseInt(state.pageSize, 10) || 0;
export const getBlogsLoading = (state) => state.loading;
export const getBlogsLoaded = (state) => state.loaded;

export const getBlog = (state) => state.blog;

export const getBlogTypes = (state) => state.blogTypes;

export const getBlogsActivityTypesSelected = (state) =>
  state.filters?.activity || [];
export const getBlogsTypesSelected = (state) => state.filters?.types || [];
