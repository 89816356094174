export const LOAD_BLOGS_DATA = "blogs/LOAD_BLOGS_DATA";
export const LOAD_BLOGS_DATA_COMPLETED = "blogs/LOAD_BLOGS_DATA_COMPLETED";

export const LOAD_ONE_BLOG = "blogs/LOAD_ONE_BLOG";
export const LOAD_ONE_BLOG_COMPLETED = "blogs/LOAD_ONE_BLOG_COMPLETED";

export const REMOVE_BLOG = "blogs/REMOVE_BLOG";

export const SET_PAGE = "blogs/SET_PAGE";

export const LOAD_BLOG_TYPES = "blogs/LOAD_BLOG_TYPES";
export const LOAD_BLOG_TYPES_COMPLETED = "blogs/LOAD_BLOG_TYPES_COMPLETED";

export const UPLOAD_BLOG_IMAGE = "blogs/UPLOAD_BLOG_IMAGE";

export const SET_DRAFT_BLOG = "blogs/SET_DRAFT_BLOG";

export const SET_DRAFT_BLOG_FIELD = "blogs/SET_DRAFT_BLOG_FIELD";

export const SAVE_BLOG = "blogs/SAVE_BLOG";

export const TOGGLE_ACTIVITY_TYPE = "blogs/TOGGLE_ACTIVITY_TYPE";
export const TOGGLE_TYPE = "blogs/TOGGLE_TYPE";

export const REMOVE_BLOG_HORIZONTAL_IMAGE =
  "blogs/REMOVE_BLOG_HORIZONTAL_IMAGE";
export const REMOVE_BLOG_VERTICAL_IMAGE = "blogs/REMOVE_BLOG_VERTICAL_IMAGE";
