const exporting = require("./exporting");
const exportinggtin = require("./exportinggtin");
const topmenus = require("./layouts/topmenu/index");
const banners = require("./marketer/banners/index");
const bannerswc = require("./marketer/bannerswc/index");
const blogs = require("./marketer/blog/index");
const subscriptions = require("./marketer/subscriptions");
const menu = require("./menu");
const baskets = require("./operator/baskets");
const reviews = require("./operator/reviews");
const orders = require("./orders/index");
const products = require("./products/index");
const signin = require("./signin");

export const reducers = {
  menu: menu.reducer,
  signin: signin.reducer,
  orders: orders.reducer,
  products: products.reducer,
  banners: banners.reducer,
  bannerswc: bannerswc.reducer,
  blogs: blogs.reducer,
  reviews: reviews.reducer,
  baskets: baskets.reducer,
  exporting: exporting.reducer,
  exportinggtin: exportinggtin.reducer,
  subscriptions: subscriptions.reducer,
  topmenus: topmenus.reducer,
};

export const sagas = [
  menu.saga(),
  signin.saga(),
  orders.saga(),
  products.saga(),
  banners.saga(),
  bannerswc.saga(),
  blogs.saga(),
  reviews.saga(),
  baskets.saga(),
  exporting.saga(),
  exportinggtin.saga(),
  subscriptions.saga(),
  topmenus.saga(),
];
