import Button from "material-ui/Button";
import R from "ramda";
import React, { useEffect } from "react";
import { connect } from "react-redux";

import Layout from "../../../../components/Layout";
import {
  loadOneBlog,
  loadBlogTypes,
  uploadBlogImage,
  setDraftBlog,
  setDraftBlogField,
  saveBlog,
  removeBlog,
  removeBlogHorizontalImage,
  removeBlogVerticalImage,
} from "../../../../modules/marketer/blog";
import OneRecordLayout from "../components/OneRecordLayout";

const { getBlog, getBlogTypes } = require("../../../../selectors").default;

const styles = {
  root: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
  },
  title: {
    padding: "0 5px",
    margin: 0,
  },
  content: {
    flex: 1,
    overflowY: "auto",
    overflowX: "hidden",
    padding: "0 10px",
  },
  bottom: {
    flex: "none",
    padding: "7px 0",
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginLeft: 10,
  },
};

const moduleUrl = "/marketer/blog";

const BlogRecordPage = (props) => {
  const {
    item,
    itemTypes,
    uploadImageLocal,
    removeHorizontalImageLocal,
    removeVerticalImageLocal,
    handleSave,
    handleRemove,
    loadOneRecordLocal,
    setDraftLocal,
    setDraftFieldLocal,
  } = props;

  useEffect(() => {
    loadOneRecordLocal();
  }, []);

  const handleClose = () => {
    props.history.push(moduleUrl);
  };

  const memoizeWith = (keyFn, fn) => {
    const cache = new Map();
    return (arg) => {
      const key = keyFn(arg);
      if (cache.has(key)) {
        return cache.get(key);
      }
      const result = fn(arg);
      cache.set(key, result);
      return result;
    };
  };

  const createSetter = (path) => (event) => {
    setDraftFieldLocal(path, event.target ? event.target.value : event);
  };

  const memoizedSetter = memoizeWith(JSON.stringify, createSetter);

  const setFieldValue1 = (path) => memoizedSetter(path);

  const setFieldValue = (() => {
    const cache = {};

    return (path) => {
      const key = JSON.stringify(path);

      if (!cache[key]) {
        cache[key] = (event) => {
          setDraftFieldLocal(path, event.target ? event.target.value : event);
        };
      }

      return cache[key];
    };
  })();

  const setDraft = (data) => {
    setDraftLocal(data);
    handleSave();
  };

  /*
  const setFieldValue = () =>
    R.memoizeWith(
      (path) => JSON.stringify(path),
      (path) => (event) => {
        props.setDraftield(
          path,
          event.target ? event.target.value : event
        );
      }
    );
    
   */

  const renderRecord = () => (
    <OneRecordLayout
      item={item}
      types={itemTypes}
      uploadImage={uploadImageLocal}
      setFieldValue={setFieldValue}
      removeHorizontalImage={removeHorizontalImageLocal}
      removeVerticalImage={removeVerticalImageLocal}
      setDraft={setDraft}
    />
  );

  return (
    <Layout
      content={
        <div style={styles.root}>
          <h3 style={styles.title}>Запись № {item?.id}</h3>
          <div style={styles.content}>{renderRecord()}</div>
          <div style={styles.bottom}>
            <Button
              raised
              color="default"
              style={styles.button}
              onClick={handleRemove}
            >
              Удалить
            </Button>
            <Button
              raised
              color="accent"
              style={styles.button}
              onClick={handleClose}
            >
              Закрыть
            </Button>
          </div>
        </div>
      }
    />
  );
};

export default connect(
  (state) => ({
    item: getBlog(state),
    itemTypes: getBlogTypes(state),
  }),
  (dispatch, ownProps) => ({
    loadOneRecordLocal() {
      const id = parseInt(ownProps.match.params.id || 0, 10);
      dispatch(loadBlogTypes());
      dispatch(loadOneBlog(id));
    },
    uploadImageLocal(data) {
      dispatch(uploadBlogImage(data));
    },
    setDraftLocal(data) {
      dispatch(setDraftBlog(data));
    },
    setDraftFieldLocal(field, value) {
      dispatch(setDraftBlogField(field, value));
    },
    handleSave() {
      dispatch(saveBlog(ownProps.history));
    },
    handleRemove() {
      if (window.confirm("Вы уверены, что хотите удалить запись?")) {
        dispatch(removeBlog(ownProps.match.params.id));
        ownProps.history.push(moduleUrl);
      }
    },
    removeHorizontalImageLocal() {
      if (
        window.confirm(
          "Вы уверены, что хотите удалить горизонтальное изображение?"
        )
      ) {
        dispatch(removeBlogHorizontalImage(ownProps.match.params.id));
      }
    },
    removeVerticalImageLocal() {
      if (
        window.confirm(
          "Вы уверены, что хотите удалить вертикальное изображение?"
        )
      ) {
        dispatch(removeBlogVerticalImage(ownProps.match.params.id));
      }
    },
  })
)(BlogRecordPage);
