const types = require("./types");

export const loadBlogsData = () => ({
  type: types.LOAD_BLOGS_DATA,
});

export const removeBlog = (id) => ({
  type: types.REMOVE_BLOG,
  id,
});

export const setPage = (page) => ({
  type: types.SET_PAGE,
  page,
});

export const loadOneBlog = (id) => ({
  type: types.LOAD_ONE_BLOG,
  id,
});

export const loadBlogTypes = () => ({
  type: types.LOAD_BLOG_TYPES,
});

export const uploadBlogImage = (data) => ({
  type: types.UPLOAD_BLOG_IMAGE,
  data,
});

export const removeBlogHorizontalImage = (id) => ({
  type: types.REMOVE_BLOG_HORIZONTAL_IMAGE,
  id,
});

export const removeBlogVerticalImage = (id) => ({
  type: types.REMOVE_BLOG_VERTICAL_IMAGE,
  id,
});

export const setDraftBlog = (blog) => ({
  type: types.SET_DRAFT_BLOG,
  blog,
});

export const setDraftBlogField = (field, value) => ({
  type: types.SET_DRAFT_BLOG_FIELD,
  field,
  value,
});

export const saveBlog = (history) => ({
  type: types.SAVE_BLOG,
  history,
});

export const toggleActivityType = (id) => ({
  type: types.TOGGLE_ACTIVITY_TYPE,
  id,
});

export const toggleType = (id) => ({
  type: types.TOGGLE_TYPE,
  id,
});
