import React, { useEffect } from "react";
import { connect } from "react-redux";

import Filter from "../../../../components/Filter";
import {
  loadBlogsData,
  toggleActivityType,
  toggleType,
  loadBlogTypes,
} from "../../../../modules/marketer/blog/index";

const { getBlogsActivityTypesSelected, getBlogsTypesSelected, getBlogTypes } =
  require("../../../../selectors").default;

const activityFilterOptions = [
  { id: "0", name: "Нет" },
  { id: "1", name: "Да" },
];

const ListSidebar = (props) => {
  const {
    loadTypesLocal,
    activityTypesSelected,
    toggleActivityTypeLocal,
    toggleTypeLocal,
    typesSelected,
    types,
  } = props;

  useEffect(() => {
    loadTypesLocal();
  }, []);
  const renderActivityFilter = () => (
    <Filter
      label="Активность"
      items={activityFilterOptions}
      selected={activityTypesSelected}
      onToggle={toggleActivityTypeLocal}
    />
  );

  const renderTypesFilter = () => (
    <Filter
      label="Тип"
      items={types}
      selected={typesSelected}
      onToggle={toggleTypeLocal}
    />
  );

  return (
    <div>
      {renderActivityFilter()}
      {renderTypesFilter()}
    </div>
  );
};

export default connect(
  (state) => ({
    activityTypesSelected: getBlogsActivityTypesSelected(state),
    typesSelected: getBlogsTypesSelected(state),
    types: getBlogTypes(state),
  }),
  (dispatch) => ({
    loadTypesLocal() {
      dispatch(loadBlogTypes());
    },
    toggleActivityTypeLocal(id) {
      dispatch(toggleActivityType(id));
      dispatch(loadBlogsData());
    },
    toggleTypeLocal(id) {
      dispatch(toggleType(id));
      dispatch(loadBlogsData());
    },
  })
)(ListSidebar);
