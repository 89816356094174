import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import axios from "axios";
import React, { Component } from "react";
import { Provider } from "react-redux";
import { Route, Redirect, Switch } from "react-router";
import { BrowserRouter as Router } from "react-router-dom";

import { setAuthResult } from "../modules/signin";
import CatalogExportGTINPage from "../routes/catalog/CatalogExportGTINPage";
import CatalogExportPage from "../routes/catalog/CatalogExportPage";
import CatalogUploadPage from "../routes/catalog/CatalogUploadPage";
import CatalogListPage from "../routes/catalog/containers/CatalogListPage";
import ProductPage from "../routes/catalog/containers/ProductPage";
import TopmenuPage from "../routes/layouts/topmenu/containers/TopmenuPage";
import TopmenusListPage from "../routes/layouts/topmenu/containers/TopmenusListPage";
import BannerPage from "../routes/marketer/banners/containers/BannerPage";
import BannersListPage from "../routes/marketer/banners/containers/BannersListPage";
import BannerswcListPage from "../routes/marketer/bannerswc/containers/BannerswcListPage";
import BannerwcPage from "../routes/marketer/bannerswc/containers/BannerwcPage";
import BlogListPage from "../routes/marketer/blog/containers/BlogListPage";
import BlogRecordPage from "../routes/marketer/blog/containers/BlogRecordPage";
import SubscriptionsListPage from "../routes/marketer/subscriptions/containers/SubscriptionsListPage";
import BasketsPage from "../routes/orders/baskets/containers/BasketsPage";
import OrderPage from "../routes/orders/containers/OrderPage";
import OrdersListPage from "../routes/orders/containers/OrdersListPage";
import ReviewsPage from "../routes/orders/reviews/containers/ReviewsPage";
import store from "../store";

import Header from "./Header";
import LoginPage from "./LoginPage";

const Cookies = require("js-cookie");

const theme = createMuiTheme();

const wrapperStyle = {
  display: "flex",
  flexDirection: "column",
  height: "100%",
};

class App extends Component {
  componentDidMount() {
    const checkAccess = () => {
      if (window.location.search.search("\\?sessionHash=") === 0) {
        const sessionHash = window.location.search.replace("?sessionHash=", "");
        if (sessionHash) {
          Cookies.set("sessionHash", sessionHash, { expires: 365, path: "/" });
          window.location.search = "";
          return;
        }
      }

      if (!Cookies.get("sessionHash")) {
        window.location.href = "/login";
        return;
      }

      axios({
        url: "/api/core/login/check",
      }).then((result) => {
        store.dispatch(setAuthResult(result.data.success));

        if (!result.data.success) {
          window.location.href = "/login";
        }
      });
    };

    if (window.location.pathname !== "/login") {
      checkAccess();
    }
  }

  render() {
    return (
      <Provider store={store}>
        <MuiThemeProvider theme={theme}>
          <Router>
            <div style={wrapperStyle}>
              <Header />
              <Route exact path="/login" component={LoginPage} />
              <Route
                exact
                path="/"
                render={() => <Redirect to="/operator/orders" />}
              />
              <Route exact path="/operator/orders" component={OrdersListPage} />
              <Switch>
                <Route
                  path="/operator/orders/new/:typeId"
                  component={OrderPage}
                />
                <Route
                  path="/operator/orders/new-by-cart/:id"
                  component={OrderPage}
                />
                <Route path="/operator/orders/:id" component={OrderPage} />
              </Switch>
              <Route exact path="/operator/reviews" component={ReviewsPage} />
              <Route exact path="/operator/baskets" component={BasketsPage} />
              <Route
                exact
                path="/catalog/products"
                component={CatalogListPage}
              />
              <Switch>
                <Route path="/catalog/products/:id" component={ProductPage} />
              </Switch>
              <Route path="/catalog/upload" component={CatalogUploadPage} />
              <Route path="/catalog/export" component={CatalogExportPage} />
              <Route
                path="/catalog/exportgtin"
                component={CatalogExportGTINPage}
              />
              <Route
                exact
                path="/marketer/banners"
                component={BannersListPage}
              />
              <Switch>
                <Route path="/marketer/banners/new" component={BannerPage} />
                <Route path="/marketer/banners/:id" component={BannerPage} />
              </Switch>
              <Route
                exact
                path="/marketer/bannerswc"
                component={BannerswcListPage}
              />
              <Switch>
                <Route
                  path="/marketer/bannerswc/new"
                  component={BannerwcPage}
                />
                <Route
                  path="/marketer/bannerswc/:id"
                  component={BannerwcPage}
                />
              </Switch>
              <Route exact path="/marketer/blog" component={BlogListPage} />
              <Switch>
                <Route path="/marketer/blog/:id" component={BlogRecordPage} />
                <Route path="/marketer/blog/new" component={BlogRecordPage} />
              </Switch>
              <Route
                exact
                path="/layouts/topmenu"
                component={TopmenusListPage}
              />
              <Switch>
                <Route path="/layouts/topmenu/new" component={TopmenuPage} />
                <Route path="/layouts/topmenu/:id" component={TopmenuPage} />
              </Switch>
              <Route
                path="/marketer/subscriptions"
                component={SubscriptionsListPage}
              />
            </div>
          </Router>
        </MuiThemeProvider>
      </Provider>
    );
  }
}

export default App;
