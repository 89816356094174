import Button from "material-ui/Button";
import Icon from "material-ui/Icon";
import IconButton from "material-ui/IconButton";
import { TableRow } from "material-ui/Table";
import React from "react";

import { default as TableCell } from "../../../orders/components/OrdersTableCell";

const styles = {
  bannerPhoto: {
    maxWidth: 100,
  },
  firstRowColumn: {
    width: 30,
  },
  openButton: {
    width: 40,
    height: 40,
  },
};

const ListRow = (props) => {
  const { item, onOpen, onRemove } = props;
  const handleOpen = () => {
    onOpen(item);
  };

  const handleRemove = () => {
    onRemove(item.id);
  };

  return (
    <TableRow>
      <TableCell style={styles.firstRowColumn}>
        <Button
          color="accent"
          fab
          onClick={handleOpen}
          style={styles.openButton}
        >
          <Icon className="material-icons">details</Icon>
        </Button>
      </TableCell>
      <TableCell>{item.id}</TableCell>
      <TableCell>
        {item.mainphoto?.url ? (
          <img
            src={item.mainphoto?.url}
            style={styles.bannerPhoto}
            alt="Фото"
          />
        ) : (
          <a>Фото отсутствует</a>
        )}
      </TableCell>
      <TableCell>{item.name}</TableCell>
      <TableCell>{item.h1}</TableCell>
      <TableCell>{parseInt(item.is_active, 10) === 1 ? "Да" : "Нет"}</TableCell>
      <TableCell>{item.text}</TableCell>
      <TableCell>{item.created}</TableCell>
      <TableCell>
        <IconButton title="Просмотр">
          <a href={item.site_url} target="_blank" rel="noreferrer">
            <Icon color="primary" className="material-icons">
              pageview
            </Icon>
          </a>
        </IconButton>
        <IconButton title="Удалить" onClick={handleRemove}>
          <Icon color="accent" className="material-icons">
            delete_forever
          </Icon>
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default ListRow;
