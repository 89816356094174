import R from "ramda";

import * as topmenusSelectors from "./modules/layouts/topmenu/selectors";
import * as bannersSelectors from "./modules/marketer/banners/selectors";
import * as bannerswcSelectors from "./modules/marketer/bannerswc/selectors";
import * as blogsSelectors from "./modules/marketer/blog/selectors";
import { selectors as subscriptionsSelectors } from "./modules/marketer/subscriptions";
import { selectors as basketsSelectors } from "./modules/operator/baskets";
import { selectors as reviewsSelectors } from "./modules/operator/reviews";
import * as orderSelectors from "./modules/orders/selectors";
import * as productSelectors from "./modules/products/selectors";
import { selectors as signinSelectors } from "./modules/signin";

const connectSelectors = (path, selectors) =>
  R.mapObjIndexed(
    (value, key, obj) =>
      (...args) => {
        const state = args[args.length - 1];
        return obj[key](...args.slice(0, args.length - 1), R.path(path, state));
      },
    selectors
  );
export default {
  ...connectSelectors(["orders"], orderSelectors),
  ...connectSelectors(["products"], productSelectors),
  ...connectSelectors(["banners"], bannersSelectors),
  ...connectSelectors(["bannerswc"], bannerswcSelectors),
  ...connectSelectors(["blogs"], blogsSelectors),
  ...connectSelectors(["reviews"], reviewsSelectors),
  ...connectSelectors(["subscriptions"], subscriptionsSelectors),
  ...connectSelectors(["baskets"], basketsSelectors),
  ...connectSelectors(["signin"], signinSelectors),
  ...connectSelectors(["topmenus"], topmenusSelectors),
};
