import {
  Select,
  MenuItem,
  TextField,
  Button,
  withStyles,
} from "@material-ui/core";
import FormControl from "material-ui/Form/FormControl";
import Icon from "material-ui/Icon";
import IconButton from "material-ui/IconButton";
import Input from "material-ui/Input";
// import { withStyles } from "material-ui/styles";
import React, { useEffect, useRef, useState } from "react";

import CustomSelect from "../../../../components/CustomSelect";
// import Tinymce from "../utils/tinymce";
import "ckeditor5/ckeditor5.css";
import { Block, Row, Cell } from "../../../../components/SubLayout";
import CKEditorLocal from "../utils/ckeditor";

import "../../../../css/style.css";

const styles = {
  img: {
    maxWidth: 300,
    maxHeight: 300,
  },
  button: {
    height: 30,
  },
};

const bannerActivityOptions = [
  { id: "0", text: "Нет" },
  { id: "1", text: "Да" },
];

const memoize = (fn) => {
  const cache = new Map();
  return (arg) => {
    if (cache.has(arg)) {
      return cache.get(arg);
    }
    const result = fn(arg);
    cache.set(arg, result);
    return result;
  };
};

const transformTypes = (options) =>
  options.map(({ id, name }) => ({ id, text: name }));

const memoizedTransformTypes = memoize(transformTypes);

const OneRecordLayout = (props) => {
  const {
    item,
    setFieldValue,
    removeHorizontalImage,
    removeVerticalImage,
    types,
    uploadImage,
    classes,
    setDraft,
  } = props;

  // const [uploading, setUploading] = useState({ 0: false });
  const uploading = { 0: false };
  const getTypeOptions = () => {
    if (!types) {
      return [];
    }
    return memoizedTransformTypes(types);
  };

  const handleUploadImage = (file_id, extendfield) => (event) => {
    const data = new FormData();
    if (item?.id) {
      data.append("id", item?.id);
    }
    if (file_id) {
      data.append("file_id", file_id);
    }
    if (extendfield) {
      data.append("extendfield", extendfield);
    }
    data.append("file", event.target.files[0]);
    // data.append("type", type);
    uploadImage(data);
  };

  const [uploadingNew, setUploadingNew] = useState(false);

  const editorToolbarRef = useRef(null);
  const editorToolbarRef1 = useRef(null);
  const [isMounted, setMounted] = useState(false);
  const [edited, setEdited] = useState(false);

  useEffect(() => {
    setMounted(true);

    return () => {
      setMounted(false);
    };
  }, []);
  const handleSelect = (file) => {
    // upload(file);
  };

  const handleRemove = (id) => {
    // remove(id);
  };

  const [formState, setFormState] = useState({
    id: "",
    is_active: "0",
    name: "",
    city: "",
    text: "",
    title: "",
    h1: "",
    description: "",
    contacts: "",
    texts: [{ caption: "", txt: "", title: "", description: "" }],
    img: [
      {
        id: null,
        caption: "",
        alt: "",
        title: "",
        h1: "",
        description: "",
        entry: null,
        products: [],
        photo: { id: null, url: "" },
      },
    ],
  });

  const handleSave = () => {
    setDraft(formState);
    const data = new FormData();
    if (item?.id) {
      data.append("id", item?.id);
    }
  };

  const addProductToItem = (itemId, newProduct) => {
    setFormState((prevData) => ({
      ...prevData,
      img: prevData.img.map((imgitem) =>
        imgitem.id === itemId
          ? { ...imgitem, products: [...imgitem.products, newProduct] }
          : item
      ),
    }));
  };

  const addTextToImgItem = (itemId, e) => {
    const { name, value } = e.target;
    setFormState((prevData) => ({
      ...prevData,
      img: prevData.img.map((imgitem) =>
        imgitem.id === itemId ? { ...imgitem, [name]: value } : imgitem
      ),
    }));
  };

  const addTextToImgItem1 = (itemId, name) => (e) => {
    const { value } = e.target;
    setFormState((prevData) => ({
      ...prevData,
      img: prevData.img.map((imgitem) =>
        imgitem.id === itemId ? { ...imgitem, [name]: value } : imgitem
      ),
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log("handleInputChange", name, value);
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleTextChange = (name) => (e) => {
    const { value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleChange = (key, value) => {
    setFormState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleChangeForImg = (itemId, key, value) => {
    setFormState((prevData) => ({
      ...prevData,
      img: prevData.img.map((imgitem) =>
        imgitem.id === itemId ? { ...imgitem, [key]: value } : imgitem
      ),
    }));
  };

  useEffect(() => {
    setFormState({ ...item });
  }, [item]);

  const [editedText, setEditedText] = useState("");
  const [editedText1, setEditedText1] = useState("");
  const [editedText2, setEditedText2] = useState("");

  const editorRef = useRef(null);
  const log = () => {
    if (editorRef.current) {
      console.log(editorRef.current.getContent());
    }
    console.log(editedText);
    console.log(editedText1);
  };

  const notShow = false;
  useEffect(() => {
    const errorHandler = (e) => {
      if (
        e.message.includes(
          "ResizeObserver loop completed with undelivered notifications1" ||
            "ResizeObserver loop limit exceeded"
        )
      ) {
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        if (resizeObserverErr) {
          resizeObserverErr.style.display = "none";
        }
      }
    };
    window.addEventListener("error", errorHandler);
    return () => {
      window.removeEventListener("error", errorHandler);
    };
  }, []);

  const renderBlockAll = () => (
    <div className="form-component">
      {
        // notShow && <Tinymce editorRef={editorRef} />
      }
      {notShow && (
        <button type="button" onClick={log}>
          Log editor content
        </button>
      )}
      {notShow && (
        <div>
          <CKEditorLocal
            editorToolbarRef={editorToolbarRef1}
            setEditedText={setEditedText1}
          />
        </div>
      )}
      <h3>Форма данных</h3>
      <div>
        {formState.id && (
          <Row>
            <Cell>ИД</Cell>
            <Cell>
              <strong>{formState.id || ""}</strong>
            </Cell>
          </Row>
        )}
        <Row>
          <Cell>
            <label>Активен:</label>
          </Cell>

          <Cell>
            <Select
              value={formState.is_active}
              onChange={handleInputChange}
              inputProps={{
                name: "is_active",
                id: "is_active",
              }}
            >
              <MenuItem value="0">Нет</MenuItem>
              <MenuItem value="1">Да</MenuItem>
            </Select>
          </Cell>
        </Row>

        <div className="formRow">
          <div className="item-mainphoto-block">
            {formState.mainphoto && (
              <div className="item-photo-image">
                <img
                  src={`${formState.mainphoto.url}?${Math.random()}`}
                  style={{ maxWidth: "100%", maxHeight: "100%" }}
                  alt="mainphoto"
                />
              </div>
            )}
            <div className="item-photo-upload">
              {!uploading[formState.mainphoto?.id] ? (
                <label>
                  <input
                    type="file"
                    accept=".jpg,.png"
                    onChange={handleUploadImage(
                      formState.mainphoto?.id,
                      "itemmain"
                    )}
                  />
                  Загрузить превью для показа в списке новостей (квадратная
                  300*300)
                </label>
              ) : (
                <span>загрузка...</span>
              )}
            </div>
          </div>
        </div>

        <div className="formRow flex g20">
          <div className="grid4 marginNormal">
            <label>Название:</label>
          </div>
          <div className="grid8">
            <TextField
              id="name"
              label="Название"
              value={formState.name}
              onChange={handleTextChange("name")}
              margin="normal"
            />
          </div>
        </div>

        <div className="formRow hidden">
          <div className="grid4">
            <label>Город:</label>
          </div>
          <div className="grid8">
            <input
              type="text"
              name="city"
              value={formState.city}
              onChange={handleInputChange}
            />
          </div>
        </div>

        <div className="formRow flex column g20">
          <div className="grid4">
            <label>Текст:</label>
          </div>
          {edited !== "maintext" ? (
            <div className="formRow flex column g20">
              <textarea
                name="text"
                value={formState.text}
                onChange={handleInputChange}
              />
              <div>
                <button
                  type="button"
                  onClick={() => {
                    setEdited("maintext");
                    setEditedText2(formState.text);
                  }}
                >
                  Редактировать
                </button>
                <Button
                  raised
                  color="primary"
                  style={styles.button}
                  onClick={() => {
                    setEdited("maintext");
                    setEditedText2(formState.text);
                  }}
                >
                  Редактировать
                </Button>
              </div>
            </div>
          ) : (
            <div>
              <CKEditorLocal
                editorToolbarRef={editorToolbarRef1}
                setEditedText={setEditedText2}
                initialText={formState.text}
              />
              <button
                type="button"
                onClick={() => {
                  setEdited(false);
                  handleChange("text", editedText2);
                }}
              >
                Принять
              </button>
            </div>
          )}
        </div>

        <div className="formRow column">
          <div className="grid4">
            <label>Заголовок страницы (title):</label>
          </div>
          <div className="grid8">
            <TextField
              id="name"
              label="title"
              value={formState.title}
              onChange={handleTextChange("title")}
              margin="normal"
              fullWidth
            />
          </div>
        </div>

        <div className="formRow column">
          <div className="grid4">
            <label>Заголовок новости (h1):</label>
          </div>
          <div className="grid8">
            <TextField
              id="name"
              label="h1"
              value={formState.h1}
              onChange={handleTextChange("h1")}
              margin="normal"
              fullWidth
            />
          </div>
        </div>

        <div className="formRow column">
          <div className="grid4">
            <label>SEO description:</label>
          </div>
          <div className="grid8">
            <TextField
              id="name"
              label="description"
              value={formState.description}
              onChange={handleTextChange("description")}
              margin="normal"
              fullWidth
            />
          </div>
        </div>

        <div className="formRow hidden">
          <div className="grid4">
            <label>Контакты:</label>
          </div>
          <div className="grid8">
            <input
              type="text"
              name="contacts"
              value={formState.contacts}
              onChange={handleInputChange}
            />
          </div>
        </div>
      </div>
      {formState.texts?.length && (
        <div className="formRow hidden">
          <div className="grid4">Тексты:</div>
          <div className="grid8">
            {formState.texts?.map((textItem, index) => (
              <div className="item-texts" key={index}>
                <div className="grid8">
                  <input
                    type="text"
                    name={`caption-${index}`}
                    value={textItem.caption}
                    onChange={(e) => {
                      const newTexts = [...formState.texts];
                      newTexts[index].caption = e.target.value;
                      setFormState({ ...formState, texts: newTexts });
                    }}
                  />
                </div>
                <div className="grid8">
                  <input
                    type="text"
                    name={`txt-${index}`}
                    value={textItem.txt}
                    onChange={(e) => {
                      const newTexts = [...formState.texts];
                      newTexts[index].txt = e.target.value;
                      setFormState({ ...formState, texts: newTexts });
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      {formState.img?.length && (
        <div className="formRow column">
          <div className="grid4">Картинки:</div>

          {formState.img?.map((oneimg, index) => (
            <div className="formRow column image-block" key={index}>
              <div>
                <div className="grid8 hidden">
                  <input
                    type="text"
                    name="caption"
                    value={oneimg.caption}
                    onChange={(e) => addTextToImgItem(oneimg.id, e)}
                  />
                  <TextField
                    id={`caption${oneimg.id}`}
                    label="caption"
                    value={oneimg.caption}
                    onChange={addTextToImgItem1(oneimg.id, "caption")}
                    margin="normal"
                  />
                </div>

                <div className="formRow grid8 column">
                  <div className="grid4">
                    <label>
                      Текст вместо картинки (alt) - Повторить заголовок новости:
                    </label>
                  </div>
                  <TextField
                    id={`alt${oneimg.id}`}
                    label="alt"
                    value={oneimg.alt}
                    onChange={addTextToImgItem1(oneimg.id, "alt")}
                    margin="normal"
                  />
                </div>

                <div className="formRow grid8 column">
                  <div className="grid4">
                    <label>текст над фото (title)</label>
                  </div>
                  <TextField
                    id={`title${oneimg.id}`}
                    label="title"
                    value={oneimg.title}
                    onChange={addTextToImgItem1(oneimg.id, "title")}
                    margin="normal"
                  />
                </div>
                <div className="grid8 hidden">
                  <label>h1</label>
                  <TextField
                    id={`h1${oneimg.id}`}
                    label="h1"
                    value={oneimg.h1}
                    onChange={addTextToImgItem1(oneimg.id, "h1")}
                    margin="normal"
                  />
                </div>

                <div className="grid4 hidden">
                  <label>left</label>
                  <input
                    type="text"
                    name="left"
                    value={oneimg.left}
                    onChange={(e) => addTextToImgItem(oneimg.id, e)}
                  />
                </div>
                <div className="grid4 hidden">
                  <label>top</label>
                  <input
                    type="text"
                    name="top"
                    value={oneimg.top}
                    onChange={(e) => addTextToImgItem(oneimg.id, e)}
                  />
                </div>
                <div className="grid4 hidden">
                  <label>width</label>
                  <input
                    type="text"
                    name="width"
                    value={oneimg.width}
                    onChange={(e) => addTextToImgItem(oneimg.id, e)}
                  />
                </div>
                <div className="grid4 hidden">
                  <label>angle</label>
                  <input
                    type="text"
                    name="angle"
                    value={oneimg.angle}
                    onChange={(e) => addTextToImgItem(oneimg.id, e)}
                  />
                </div>
                <div className="grid4 hidden">
                  <label>class</label>
                  <input
                    type="text"
                    name="class"
                    value={oneimg.class}
                    onChange={(e) => addTextToImgItem(oneimg.id, e)}
                  />
                </div>
                <div className="grid4 hidden">
                  <label>style</label>
                  <input
                    type="text"
                    name="style"
                    value={oneimg.style}
                    onChange={(e) => addTextToImgItem(oneimg.id, e)}
                  />
                </div>
                <div className="grid4 hidden">
                  <label>level</label>
                  <input
                    type="text"
                    name="level"
                    value={oneimg.level}
                    onChange={(e) => addTextToImgItem(oneimg.id, e)}
                  />
                </div>
                <div className="grid4 hidden">
                  <label>position</label>
                  <input
                    type="text"
                    name="position"
                    value={oneimg.position}
                    onChange={(e) => addTextToImgItem(oneimg.id, e)}
                  />
                </div>
                <div className="grid4 hidden">
                  <input
                    type="text"
                    name="sort"
                    value={oneimg.sort}
                    onChange={(e) => addTextToImgItem(oneimg.id, e)}
                  />
                </div>
                <div className="formRow">
                  <div className="grid4">
                    Дата добавления {oneimg.data_insert}
                  </div>
                  <div className="grid4 hidden">
                    Дата редактирования {oneimg.data_edit}
                  </div>
                </div>
                <div className="grid4 hidden">
                  filename {oneimg.filename}
                  <input
                    type="hidden"
                    value={oneimg.filename}
                    onChange={(e) => handleChange("filename", e.target.value)}
                  />
                </div>
                <div className="formRow column">
                  <div className="item-photo-image">
                    {oneimg.photo?.url ? (
                      <img
                        src={`${oneimg.photo?.url}?${Math.random()}`}
                        style={{
                          maxWidth: 200,
                          maxHeight: 200,
                          width: "100%",
                          height: "100%",
                        }}
                        alt="Product"
                      />
                    ) : (
                      <div>Фото отсутствует</div>
                    )}
                  </div>
                  <div className="item-photo-upload">
                    {!uploading[oneimg?.photo?.id] && (
                      <div>
                        Загрузить картинку
                        <input
                          type="file"
                          multiple
                          onChange={handleUploadImage(oneimg?.photo?.id)}
                          accept=".jpg,.png"
                        />
                      </div>
                    )}
                    {uploading[oneimg?.photo?.id] && <p>Загрузка файла...</p>}
                  </div>
                </div>
                <div className="formRow grid8 column">
                  <label>текст под фото (description)</label>
                  {edited !== oneimg.id ? (
                    <div className="formRow grid8 column">
                      <TextField
                        id={`description${oneimg.id}`}
                        label="description"
                        value={oneimg.description}
                        onChange={addTextToImgItem1(oneimg.id, "description")}
                        margin="normal"
                      />
                      <div className="formRow grid8 ">
                        <button
                          type="button"
                          onClick={() => {
                            setEdited(oneimg.id);
                            setEditedText2(oneimg.description);
                          }}
                        >
                          Редактировать
                        </button>
                        <Button
                          raised
                          color="primary"
                          style={styles.button}
                          onClick={() => {
                            setEdited(oneimg.id);
                            setEditedText2(oneimg.description);
                          }}
                        >
                          Редактировать
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <div className="editor">
                      <CKEditorLocal
                        editorToolbarRef={editorToolbarRef1}
                        setEditedText={setEditedText2}
                        initialText={oneimg.description}
                      />
                      <button
                        type="button"
                        onClick={() => {
                          setEdited(false);
                          handleChangeForImg(
                            oneimg.id,
                            "description",
                            editedText2
                          );
                        }}
                      >
                        Принять
                      </button>
                    </div>
                  )}
                </div>
              </div>
              <div
                className="formRow grid8 right"
                style={{ overflow: "auto", height: 250 }}
              >
                {oneimg.products &&
                  oneimg.products.map((oneproduct, index) => (
                    <div className="item-oneimg-products" key={index}>
                      <div
                        className="item-oneimg-product-item"
                        data-product-id={oneproduct.ID}
                      >
                        <div className="image">
                          <img
                            src={oneproduct.PREVIEW_PICTURE_URL}
                            alt="Product"
                          />
                        </div>
                        <div className="info">
                          <ul>
                            <li>
                              <span>ИД:</span> {oneproduct.ID}
                            </li>
                            <li>
                              <span>{oneproduct.PROPERTIES.ARTICUL.name}:</span>
                              {oneproduct.PROPERTIES.ARTICUL.print_value}
                            </li>
                            <li>
                              <span>Категория:</span>
                              {oneproduct.SECTION_NAME}
                            </li>
                            <li>
                              <span>{oneproduct.PROPERTIES.BRAND.name}:</span>
                              {oneproduct.PROPERTIES.BRAND.print_value}
                            </li>
                            <li>
                              <span>
                                {oneproduct.PROPERTIES.COLLECTION.name}:
                              </span>
                              {oneproduct.PROPERTIES.COLLECTION.print_value}
                            </li>
                            {oneproduct.PROPERTIES.COLOR && (
                              <li>
                                <span>{oneproduct.PROPERTIES.COLOR.name}:</span>
                                {oneproduct.PROPERTIES.COLOR.print_value}
                              </li>
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
              {!uploading[oneimg?.photo?.id] && (
                <div>
                  <button
                    type="button"
                    onClick={() => handleRemove(oneimg.photo?.id)}
                  >
                    Удалить блок с картинкой
                  </button>
                </div>
              )}
            </div>
          ))}
        </div>
      )}
      {formState?.id && (
        <div className="formRow">
          <div className="grid4">Фото:</div>
          <div className="grid8">
            <div className="item-photo item-photo-new">
              <div className="item-photo-image">
                <br />
              </div>
              <div className="item-photo-upload">
                {!uploadingNew && (
                  <div>
                    Добавить
                    <input
                      type="file"
                      onChange={handleUploadImage()}
                      accept=".jpg,.png"
                    />
                  </div>
                )}
                {uploadingNew && <span>загрузка...</span>}
              </div>
              <div className="clear" />
            </div>
          </div>
        </div>
      )}

      <button type="button" onClick={handleSave}>
        Сохранить
      </button>
      <Button raised color="primary" style={styles.button} onClick={handleSave}>
        Сохранить
      </Button>
    </div>
  );

  const renderBlockInfo = () => (
    <Block title="Информация о записи">
      <Row>
        <Cell>ИД</Cell>
        <Cell>
          <strong>{item.id || ""}</strong>
        </Cell>
      </Row>
      <Row>
        <Cell>Адрес</Cell>
        <Cell>
          <TextField value={item.url || ""} onChange={setFieldValue(["url"])} />
        </Cell>
      </Row>
      <Row>
        <Cell>Активен</Cell>
        <Cell>
          <CustomSelect
            options={bannerActivityOptions}
            value={item.is_active}
            onChange={setFieldValue(["is_active"])}
          />
        </Cell>
      </Row>
      <Row>
        <Cell>Порядок</Cell>
        <Cell>
          <TextField
            value={item.weight || ""}
            onChange={setFieldValue(["weight"])}
          />
        </Cell>
      </Row>
      <Row>
        <Cell>Название</Cell>
        <Cell>
          <TextField
            value={item.name || ""}
            onChange={setFieldValue(["name"])}
          />
        </Cell>
      </Row>
      <Row>
        <Cell>Тип</Cell>
        <Cell>
          <CustomSelect
            options={getTypeOptions()}
            value={item.type_id}
            onChange={setFieldValue(["type_id"])}
          />
        </Cell>
      </Row>
      <Row>
        <Cell>Уровень</Cell>
        <Cell>
          <TextField
            value={item.steplevel || ""}
            onChange={setFieldValue(["steplevel"])}
          />
        </Cell>
      </Row>
      <Row>
        <Cell>Интервал показа</Cell>
        <Cell>
          <TextField
            label="От"
            value={item.date_show_from || ""}
            onChange={setFieldValue(["date_show_from"])}
          />
          <TextField
            label="До"
            value={item.date_show_to || ""}
            onChange={setFieldValue(["date_show_to"])}
          />
        </Cell>
      </Row>
    </Block>
  );

  const renderBlockImages = () => (
    <Block title="Изображения">
      <Row>
        <Cell>
          Горизонтальное
          <div style={{ display: "flex", alignItems: "center" }}>
            <FormControl onChange={handleUploadImage("horizontal")}>
              <Input type="file" accept="image/jpeg, image/png" />
            </FormControl>

            {!!item.image_url && (
              <IconButton title="Удалить" onClick={removeHorizontalImage}>
                <Icon className="material-icons">delete_forever</Icon>
              </IconButton>
            )}
          </div>
        </Cell>
        <Cell>
          {!!item.image_url && (
            <img
              alt="Картинка горизонтальная"
              className={classes.img}
              src={item.image_url || ""}
            />
          )}
        </Cell>
      </Row>
      <Row>
        <Cell>
          Вертикальное
          <div style={{ display: "flex", alignItems: "center" }}>
            <FormControl onChange={handleUploadImage("vertical")}>
              <Input type="file" accept="image/jpeg, image/png" />
            </FormControl>

            {!!item.image_vertical_url && (
              <IconButton title="Удалить" onClick={removeVerticalImage}>
                <Icon className="material-icons">delete_forever</Icon>
              </IconButton>
            )}
          </div>
        </Cell>
        <Cell>
          {!!item.image_vertical_url && (
            <img
              alt="Картинка вертикальная"
              className={classes.img}
              src={item.image_vertical_url || ""}
            />
          )}
        </Cell>
      </Row>
    </Block>
  );

  return (
    <div>
      {renderBlockAll()}
      {false && renderBlockInfo()}
      {false && item.id && renderBlockImages()}
    </div>
  );
};

export default withStyles(styles)(OneRecordLayout);
