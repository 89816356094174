// import R from "ramda";

const types = require("./types");

const initialState = {
  blogs: [],
  blog: {},
  pageSize: 40,
  total: 0,
  page: 1,
  blogTypes: [],
  loading: false,
  loaded: false,
  filters: {
    activity: [],
    types: [],
  },
};

const toggleArrayItem = (path, value, state) => {
  const values = path.reduce((acc, key) => acc && acc[key], state) || [];
  const index = values.indexOf(value);

  const newValues =
    index === -1 ? [...values, value] : values.filter((_, i) => i !== index);

  return path.reduceRight(
    (acc, key, i) => {
      if (i === 0) return { ...acc, [key]: newValues };
      return { ...acc, [key]: acc[key] };
    },
    { ...state }
  );
};
/* (path, value, state) => {
  const lens = R.lensPath(path);

  const values = R.view(lens, state) || [];
  const index = R.indexOf(value, values);
  const transform = index === -1 ? R.append(value) : R.remove(index, 1);

  return R.set(lens, transform(values), state);
};
*/

const toggleFilterArrayItem = (path, value, state) => ({
  ...toggleArrayItem(path, value, state),
  page: 1,
});

const assocPath = (path, value, obj) => {
  const newObj = { ...obj };
  let current = newObj;

  for (let i = 0; i < path.length - 1; i++) {
    const key = path[i];
    current[key] = { ...(current[key] || {}) };
    current = current[key];
  }

  current[path[path.length - 1]] = value;
  return newObj;
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.LOAD_BLOGS_DATA:
      return {
        ...state,
        loading: true,
      };
    case types.LOAD_BLOGS_DATA_COMPLETED:
      return {
        ...state,
        blogs: action.blogs,
        pageSize: action.pageSize,
        total: action.total,
        loading: false,
        loaded: true,
      };
    case types.SET_PAGE:
      return { ...state, page: action.page };
    case types.REMOVE_BLOG:
      return {
        ...state,
        blogs: state.blogs.filter((row) => row.id !== action.id),
      };
    case types.LOAD_ONE_BLOG_COMPLETED:
      return { ...state, blog: action.blog };
    case types.LOAD_BLOG_TYPES_COMPLETED:
      return { ...state, blogTypes: action.blogTypes };
    case types.SET_DRAFT_BLOG:
      return { ...state, blog: action.blog };
    case types.SET_DRAFT_BLOG_FIELD:
      return assocPath(["blog", ...action.field], action.value, state);
    case types.TOGGLE_ACTIVITY_TYPE:
      return toggleFilterArrayItem(["filters", "activity"], action.id, state);
    case types.TOGGLE_TYPE:
      return toggleFilterArrayItem(["filters", "types"], action.id, state);
    default:
      return state;
  }
}
