import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
  AccessibilityHelp,
  Alignment,
  Autoformat,
  AutoImage,
  AutoLink,
  Autosave,
  BlockQuote,
  Bold,
  ClassicEditor,
  CloudServices,
  Code,
  CodeBlock,
  Essentials,
  FindAndReplace,
  FontBackgroundColor,
  FontColor,
  FontFamily,
  FontSize,
  FullPage,
  GeneralHtmlSupport,
  Heading,
  Highlight,
  HorizontalLine,
  HtmlComment,
  HtmlEmbed,
  ImageBlock,
  ImageCaption,
  ImageInline,
  ImageInsertViaUrl,
  ImageResize,
  ImageStyle,
  ImageTextAlternative,
  ImageToolbar,
  ImageUpload,
  Indent,
  IndentBlock,
  Italic,
  Link,
  LinkImage,
  List,
  ListProperties,
  MediaEmbed,
  Paragraph,
  PasteFromOffice,
  RemoveFormat,
  SelectAll,
  ShowBlocks,
  SourceEditing,
  SpecialCharacters,
  SpecialCharactersArrows,
  SpecialCharactersCurrency,
  SpecialCharactersEssentials,
  SpecialCharactersLatin,
  SpecialCharactersMathematical,
  SpecialCharactersText,
  Strikethrough,
  Subscript,
  Superscript,
  Table,
  TableCaption,
  TableCellProperties,
  TableColumnResize,
  TableProperties,
  TableToolbar,
  TextPartLanguage,
  TextTransformation,
  TodoList,
  Underline,
  Undo,
} from "ckeditor5";
import coreTranslations from "ckeditor5/translations/ru.js";
import React, { useEffect, useState } from "react";

const CKEditorLocal = ({ setEditedText, editorToolbarRef, initialText }) => {
  const [isMounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);

    return () => {
      setMounted(false);
    };
  }, []);
  return (
    <div>
      <div ref={editorToolbarRef} />
      <div>
        {isMounted && (
          <CKEditor
            editor={ClassicEditor}
            data=""
            config={{
              translations: [coreTranslations],
              plugins: [
                AccessibilityHelp,
                Alignment,
                Autoformat,
                AutoImage,
                AutoLink,
                Autosave,
                BlockQuote,
                Bold,
                CloudServices,
                Code,
                CodeBlock,
                Essentials,
                FindAndReplace,
                FontBackgroundColor,
                FontColor,
                FontFamily,
                FontSize,
                FullPage,
                GeneralHtmlSupport,
                Heading,
                Highlight,
                HorizontalLine,
                HtmlComment,
                HtmlEmbed,
                ImageBlock,
                ImageCaption,
                ImageInline,
                ImageInsertViaUrl,
                ImageResize,
                ImageStyle,
                ImageTextAlternative,
                ImageToolbar,
                ImageUpload,
                Indent,
                IndentBlock,
                Italic,
                Link,
                LinkImage,
                List,
                ListProperties,
                MediaEmbed,
                Paragraph,
                PasteFromOffice,
                RemoveFormat,
                SelectAll,
                ShowBlocks,
                SourceEditing,
                SpecialCharacters,
                SpecialCharactersArrows,
                SpecialCharactersCurrency,
                SpecialCharactersEssentials,
                SpecialCharactersLatin,
                SpecialCharactersMathematical,
                SpecialCharactersText,
                Strikethrough,
                Subscript,
                Superscript,
                Table,
                TableCaption,
                TableCellProperties,
                TableColumnResize,
                TableProperties,
                TableToolbar,
                TextPartLanguage,
                TextTransformation,
                TodoList,
                Underline,
                Undo,
              ],
              toolbar: [
                "undo",
                "redo",
                "|",
                "blockQuote",
                "link",
                "numberedList",
                "bulletedList",
                "bold",
                "italic",
                "underline",
                "strikethrough",
                "subscript",
                "superscript",
                "code",
                "removeFormat",
                "|",
                "insertImageViaUrl",
                "findAndReplace",
                "|",
                "heading",
                "|",
                "fontSize",
                "fontFamily",
                "fontColor",
                "fontBackgroundColor",
                "|",
                "specialCharacters",
                "horizontalLine",
                "highlight",
                "codeBlock",
                "|",
                "alignment",
                "|",
                "todoList",
                "outdent",
                "indent",
                "sourceEditing",
                "showBlocks",
                "textPartLanguage",
              ],
              menuBar: {
                isVisible: true,
              },
              fontFamily: {
                supportAllValues: true,
              },
              fontSize: {
                options: [10, 12, 14, 16, "default", 18, 20, 22],
                supportAllValues: true,
              },
              heading: {
                options: [
                  {
                    model: "paragraph",
                    title: "Paragraph",
                    class: "ck-heading_paragraph",
                  },
                  {
                    model: "heading2",
                    view: "h2",
                    title: "Heading 2",
                    class: "ck-heading_heading2",
                  },
                  {
                    model: "heading3",
                    view: "h3",
                    title: "Heading 3",
                    class: "ck-heading_heading3",
                  },
                  {
                    model: "heading4",
                    view: "h4",
                    title: "Heading 4",
                    class: "ck-heading_heading4",
                  },
                  {
                    model: "heading5",
                    view: "h5",
                    title: "Heading 5",
                    class: "ck-heading_heading5",
                  },
                  {
                    model: "heading6",
                    view: "h6",
                    title: "Heading 6",
                    class: "ck-heading_heading6",
                  },
                ],
              },
              htmlSupport: {
                allow: [
                  {
                    name: /^.*$/,
                    styles: true,
                    attributes: true,
                    classes: true,
                  },
                ],
              },
              image: {
                toolbar: [
                  "toggleImageCaption",
                  "imageTextAlternative",
                  "|",
                  "imageStyle:inline",
                  "imageStyle:wrapText",
                  "imageStyle:breakText",
                  "|",
                  "resizeImage",
                ],
              },
              initialData: initialText || "",
              language: "ru",
              link: {
                addTargetToExternalLinks: true,
                defaultProtocol: "https://",
                decorators: {
                  toggleDownloadable: {
                    mode: "manual",
                    label: "Downloadable",
                    attributes: {
                      download: "file",
                    },
                  },
                },
              },
              list: {
                properties: {
                  styles: true,
                  startIndex: true,
                  reversed: true,
                },
              },
              placeholder: "Type or paste your content here!",
              table: {
                contentToolbar: [
                  "tableColumn",
                  "tableRow",
                  "mergeTableCells",
                  "tableProperties",
                  "tableCellProperties",
                ],
              },
            }}
            onChange={(event, editor) => {
              const data = editor.getData();
              console.log({ event, editor, data });
              setEditedText(data);
            }}
            onReady={(editor) => {
              if (editorToolbarRef.current) {
                editorToolbarRef.current.appendChild(
                  editor.ui.view.toolbar.element
                );
              }
            }}
            onAfterDestroy={(editor) => {
              if (editorToolbarRef.current) {
                Array.from(editorToolbarRef.current.children).forEach((child) =>
                  child.remove()
                );
              }
            }}
          />
        )}
      </div>
    </div>
  );
};
export default CKEditorLocal;
